export const MATCHING_LOADING = 'MATCHING_LOADING';
export const MATCHING_TIMEOUT = 'MATCHING_TIMEOUT';
export const MATCHING_ERROR = 'MATCHING_ERROR';
export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const SUBMIT_QUESTIONNAIRE = 'SUBMIT_QUESTIONNAIRE';
export const MATCHING_SELECTION_SAVE = 'MATCHING_SELECTION_SAVE';
export const COACH_PROPOSALS_GET = 'COACH_PROPOSALS_GET';
export const COACH_PROPOSALS_SHOW_MORE = 'COACH_PROPOSALS_SHOW_MORE';
export const COACH_PROPOSAL_SUBMIT = 'COACH_PROPOSAL_SUBMIT';
export const COACH_AVAILABILITIES_GET = 'COACH_AVAILABILITIES_GET';
export const COACH_PROPOSALS_SHOW_MORE_TRACKING = 'COACH_PROPOSALS_SHOW_MORE_TRACKING';
export const RESET_STATE_FOR_RESUBMIT = 'RESET_STATE_FOR_RESUBMIT';
export const STORE_SKIPPED_QUESTION_INDEXES = 'STORE_SKIPPED_QUESTION_INDEXES';
export const QUESTIONNAIRE_SUBMITTED = 'QUESTIONNAIRE_SUBMITTED';
