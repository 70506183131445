import { isEmpty } from 'lodash';
import * as Types from './types';

function getJourneyFromLocalStorage() {
  const journey = localStorage.getItem('currentJourney');
  return journey ? JSON.parse(journey) : undefined;
}

const initialState = {
  coach: {},
  coachProfile: {},
  error: null,
  coacheeList: [],
  loading: false,
  coachee: {},
  currentJourney: getJourneyFromLocalStorage(),
  journeys: []
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.USER_GET: {
      // TEMP: support shape of response from mono
      if (action.payload.user) {
        return {
          ...state,
          ...action.payload.user,
          coach: action.payload.coach,
          loading: false
        };
      }

      const { currentJourney, ...otherProps } = action.payload;
      const journeyExists = !!(otherProps.journeys || []).find(
        ({ id, programId }) =>
          id === state.currentJourney?.id && programId === state.currentJourney.programId
      );
      const isValidJourney = journeyExists && !isEmpty(state.currentJourney);
      if (!isValidJourney) {
        localStorage.removeItem('currentJourney');
      }
      return {
        ...state,
        ...otherProps,
        // keep selection, if available
        // eslint-disable-next-line no-nested-ternary
        ...(isValidJourney ? { currentJourney: state.currentJourney } : { currentJourney }),
        loading: false
      };
    }
    case Types.COACHEES_GET: {
      return {
        ...state,
        coacheeList: action.payload,
        loading: false
      };
    }
    case Types.COACHEE_GET: {
      return {
        ...state,
        coachee: action.payload,
        loading: false
      };
    }
    case Types.COACH_GET: {
      return {
        ...state,
        coach: action.payload.coachProfile,
        coachProfile: action.payload.coachProfile,
        loading: false
      };
    }
    case Types.USER_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.USER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case Types.USER_UPDATED: {
      return {
        ...state,
        ...action.payload
      };
    }
    case Types.SET_CURRENT_JOURNEY: {
      return {
        ...state,
        currentJourney: action.payload
      };
    }
    default:
      return state;
  }
};
