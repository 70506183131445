import * as Types from './types';
import moment from 'moment';

export const defaultDetailedAvailabilityInfo = () => ({
  availability: { recurring: [], onetime: [] },
  blocks: { coachhub: [], external: [] }
});

const initialState = {
  error: null,
  loading: false,
  newSession: {},
  availabilities: [],
  availabilitiesLoading: false,
  detailedAvailabilityInfo: defaultDetailedAvailabilityInfo(),
  detailedAvailabilityInfoLoading: false,
  blockings: [],
  // TODO
  // remove everything related to bookableTimeframe
  // and use instead program on programStore
  hasBookableTimeframes: undefined,
  bookableTimeframe: undefined,
  statusProposals: [],
  pending: {
    loading: false,
    sessions: undefined
  },
  showWelcome: false,
  allSessions: [],
  sessionProposalLoading: false,
  ratingContent: {},
  cancelledAndMissedSessions: null,
  cancelledSessions: null
};

export const sessionsReducer = (state = initialState, action) => {
  const { detailedAvailabilityInfo } = state;
  switch (action.type) {
    case Types.SESSIONS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Types.SESSIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case Types.GET_SESSIONS_BY_USER:
      return {
        ...state,
        allSessions: action.payload,
        showWelcome: true,
        loading: false,
        error: null
      };
    case Types.GET_BOOKABLE_TIMEFRAMES:
      return {
        ...state,
        hasBookableTimeframes: !!action.payload?.length,
        bookableTimeframe: action.payload?.[0],
        loading: false,
        error: null
      };
    case Types.CREATE_SESSION:
      return {
        ...state,
        allSessions: [...state.allSessions, action.payload].sort(
          (a, b) => new Date(a.startsAt) - new Date(b.startsAt)
        ),
        newSession: action.payload,
        bookableTimeframe: state.bookableTimeframe?.remainingSessions
          ? {
              ...state.bookableTimeframe,
              remainingSessions: state.bookableTimeframe.remainingSessions - 1
            }
          : state.bookableTimeframe,
        loading: false,
        error: null
      };
    case Types.CANCEL_SESSION:
      return {
        ...state,
        allSessions: state.allSessions.filter(s => s.id !== action.payload),
        loading: false,
        error: null
      };
    case Types.RESCHEDULE_SESSION:
      return {
        ...state,
        allSessions: [
          ...state.allSessions.filter(s => s.id !== action.payload.id),
          action.payload
        ].sort((a, b) => new Date(a.startsAt) - new Date(b.startsAt)),
        loading: false,
        error: null
      };
    case Types.AVAILABILITIES_LOADING:
      return {
        ...state,
        availabilitiesLoading: true
      };
    case Types.AVAILABILITIES_ERROR:
      return {
        ...state,
        error: action.payload,
        availabilitiesLoading: false
      };
    case Types.GET_AVAILABILITIES:
      return {
        ...state,
        availabilities: action.payload,
        loading: false,
        availabilitiesLoading: false,
        error: null
      };
    case Types.DETAILED_AVAILABILITY_INFO_LOADING:
      return {
        ...state,
        detailedAvailabilityInfoLoading: true,
        detailedAvailabilityInfoError: null,
        error: null
      };
    case Types.DETAILED_AVAILABILITY_INFO_ERROR:
      return {
        ...state,
        error: action.payload,
        detailedAvailabilityInfoError: action.payload,
        detailedAvailabilityInfoLoading: false
      };
    case Types.GET_DETAILED_AVAILABILITY_INFO:
      return {
        ...state,
        detailedAvailabilityInfo: action.payload,
        loading: false,
        detailedAvailabilityInfoLoading: false,
        detailedAvailabilityInfoError: null,
        error: null
      };
    case Types.BLOCK_SLOT_MOVED:
      if (
        detailedAvailabilityInfo &&
        detailedAvailabilityInfo.blocks &&
        detailedAvailabilityInfo.blocks.coachhub
      ) {
        const { oldSlot } = action.payload;
        const oldStart = moment(oldSlot.start);
        const oldEnd = moment(oldSlot.end);
        const filterFn = oldSlot.id
          ? block => block.id !== oldSlot.id
          : block => !moment(block.start).isSame(oldStart) && !moment(block.end).isSame(oldEnd);
        detailedAvailabilityInfo.blocks.coachhub =
          detailedAvailabilityInfo.blocks.coachhub.filter(filterFn);

        detailedAvailabilityInfo.blocks.coachhub = [
          ...state.detailedAvailabilityInfo.blocks.coachhub,
          action.payload.newSlot
        ];
        const blockings = state.blockings
          .filter(item => filterFn(item.block_period))
          .concat([
            {
              block_period: action.payload.newSlot
            }
          ]);
        return {
          ...state,
          detailedAvailabilityInfo,
          detailedAvailabilityInfoLoading: false,
          blockings,
          loading: false,
          error: null
        };
      }
      return state;
    case Types.GET_BLOCKINGS:
      return {
        ...state,
        blockings: action.payload ?? [],
        loading: false,
        error: null
      };

    case Types.BLOCK_SLOT_AVAILABILITY:
      if (
        detailedAvailabilityInfo &&
        detailedAvailabilityInfo.blocks &&
        detailedAvailabilityInfo.blocks.coachhub
      ) {
        detailedAvailabilityInfo.blocks.coachhub = [
          ...state.detailedAvailabilityInfo.blocks.coachhub,
          {
            start: action.payload.startDate,
            end: action.payload.endDate
          }
        ];
        return {
          ...state,
          blockings: [
            ...state.blockings,
            {
              block_period: {
                id: action.payload.id, // id is needed for the AV service
                start: action.payload.startDate,
                end: action.payload.endDate
              }
            }
          ],
          detailedAvailabilityInfo,
          detailedAvailabilityInfoLoading: false,
          loading: false,
          error: null
        };
      }
      return {
        ...state,
        blockings: [
          ...state.blockings,
          {
            block_period: {
              id: action.payload.id, // id is needed for the AV service
              start: action.payload.startDate,
              end: action.payload.endDate
            }
          }
        ],
        loading: false,
        error: null
      };

    case Types.UNBLOCK_SLOT: {
      const { toRemove } = action.payload;
      const updatedBlockings = state.blockings
        ? state.blockings.filter(item => item.block_period !== toRemove)
        : [];
      if (detailedAvailabilityInfo?.blocks?.coachhub) {
        const start = moment(toRemove.start);
        const end = moment(toRemove.end);
        detailedAvailabilityInfo.blocks.coachhub =
          state.detailedAvailabilityInfo.blocks.coachhub.filter(
            item => !moment(item.start).isSame(start) && !moment(item.end).isSame(end)
          );
        return {
          ...state,
          blockings: [...updatedBlockings],
          detailedAvailabilityInfo,
          detailedAvailabilityInfoLoading: false,
          loading: false,
          error: null
        };
      }
      return {
        ...state,
        blockings: [...updatedBlockings],
        loading: false,
        error: null
      };
    }
    case Types.PENDING_SESSIONS_LOADING:
      return {
        ...state,
        pending: {
          loading: true,
          sessions: undefined
        }
      };

    case Types.SET_PENDING_SESSIONS_COACH:
    case Types.SET_PENDING_SESSIONS_COACHEE:
      return {
        ...state,
        pending: {
          loading: false,
          sessions: action.sessions
        }
      };

    default:
      return state;
  }
};
