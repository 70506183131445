import { BrowseLearningActivities, LearningActivitiesState } from '../types';
import { AnyAction } from 'redux';
import {
  LEARNING_ACTIVITIES_WITH_FILTERS_ERROR,
  LEARNING_ACTIVITIES_WITH_FILTER_GET,
  LEARNING_ACTIVITIES_LOADING,
  LEARNING_ACTIVITIES_WITH_FILTERS_CLEAR,
  GROUPED_LEARNING_ACTIVITIES_LOADING,
  GROUPED_LEARNING_ACTIVITIES_GET_WITH_QUERY,
  RECENTLY_VIEWED_CLEAR,
  RECENTLY_VIEWED_ERROR,
  RECENTLY_VIEWED_LOADING,
  RECENTLY_VIEWED_GET,
  CLEAR_BROWSE_LEARNING_ACTIVITIES
} from './types';
import {
  LearningActivity,
  LearningActivityCollection,
  LearningActivityNugget,
  LearningActivityType,
  LearningNuggetEventRating,
  LearningNuggetProgress
} from '../../model/model';
import {
  LearningActivityCollectionDto,
  LearningActivityLearningNuggetDto,
  LearningNuggetEventRatingDto,
  LearningNuggetProgressDto
} from '../../apis/learningEngagement.api';
import { uniqBy } from 'lodash';

const mapRatingLearningNuggetEventDto = (
  ratingDto: LearningNuggetEventRatingDto
): LearningNuggetEventRating => ({
  ...ratingDto,
  feedbackMessage: ratingDto.feedbackMessage ?? undefined
});

const mapLearningNuggetProgress = (
  progress?: LearningNuggetProgressDto
): LearningNuggetProgress | undefined => {
  if (progress?.status === undefined) {
    return undefined;
  }

  return {
    status: progress.status
  };
};

export const mapLearningActivityCollection = (
  collection: LearningActivityCollectionDto
): LearningActivityCollection => ({
  _type: LearningActivityType.Collection,
  ...collection,
  createdAt: new Date(collection.createdAt),
  updatedAt: new Date(collection.updatedAt),
  localisedLearningNuggets: collection.localisedLearningNuggets?.map(lln => ({
    ...lln,
    ratingLearningNuggetEvent: lln.ratingLearningNuggetEvent
      ? mapRatingLearningNuggetEventDto(lln.ratingLearningNuggetEvent)
      : undefined,
    createdAt: new Date(lln.createdAt),
    updatedAt: new Date(lln.updatedAt)
  })),
  next: collection.next
    ? mapLearningActivityCollection(collection.next as LearningActivityCollectionDto)
    : collection.next
});

export const mapLearningActivityNugget = (
  learningNugget: LearningActivityLearningNuggetDto
): LearningActivityNugget => ({
  _type: LearningActivityType.Nugget,
  id: learningNugget.id,
  learningNuggetId: learningNugget.learningNuggetId,
  localisedLearningNuggetId: learningNugget.localisedId,
  name: learningNugget.content.title,
  introduction: learningNugget.content.introduction_text || learningNugget.content.info_text,
  infoText: learningNugget.content.info_text,
  mainCategory:
    typeof learningNugget.content.main_category === 'string'
      ? learningNugget.content.main_category
      : learningNugget.content.main_category[0],
  focusAreas: learningNugget.content.focus_areas,
  type: learningNugget.content.general_type,
  provider: learningNugget.content.provider,
  nuggetTypeTitle: learningNugget.content.main_category,
  imageHeader:
    learningNugget.content.panorama_image?.filename || learningNugget.content.main_image?.filename,
  content: learningNugget.content.body,
  locale: learningNugget.locale,
  rating: learningNugget.ratingLearningNuggetEvent?.rating,
  feedbackMessage: learningNugget.ratingLearningNuggetEvent?.feedbackMessage ?? undefined,
  assignedLearningNuggets: learningNugget?.assignedLearningNuggets || [],
  likes: learningNugget?.nonNegativeRatingCount || 0,
  next: learningNugget.next,
  progress: mapLearningNuggetProgress(learningNugget.progress),
  collections: learningNugget.collections
    ? learningNugget.collections.map(col =>
        mapLearningActivityCollection(col as LearningActivityCollectionDto)
      )
    : learningNugget.collections
});

export const mapLearningActivity = (
  learningActivity: LearningActivityLearningNuggetDto | LearningActivityCollectionDto
) => {
  if (learningActivity.type === LearningActivityType.Collection) {
    return mapLearningActivityCollection(learningActivity);
  }
  return mapLearningActivityNugget(learningActivity);
};

const updateBrowseLearningActivitiesByLanguage = (
  currentState: BrowseLearningActivities,
  language: string,
  newActivityDtoList: (LearningActivityLearningNuggetDto | LearningActivityCollectionDto)[]
): LearningActivity[] => {
  let currentList: LearningActivity[] = [];
  if (currentState && currentState[language]?.length) {
    currentList = currentState[language];
  }

  const newActivities = newActivityDtoList.map(
    (learningActivity: LearningActivityLearningNuggetDto | LearningActivityCollectionDto) =>
      mapLearningActivity(learningActivity)
  );

  return uniqBy(currentList.concat(newActivities), item => item.id);
};

const initialState: LearningActivitiesState = {
  loading: false,
  browseLearningActivities: null,
  browseTraceId: null,
  browseTotalCountForQuery: 0,
  groupedLoading: false,
  recentlyViewedLoading: false,
  recentlyViewedLearningActivities: null,
  recentlyViewedTraceId: null
};

export const learningActivitiesReducer = (
  state: LearningActivitiesState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case LEARNING_ACTIVITIES_WITH_FILTERS_CLEAR: {
      return {
        ...state,
        browseLearningActivities: null,
        browseTraceId: null,
        error: null
      };
    }
    /**
     * Resets browseLearningActivities to empty array. Benefits:
     * - forces InfiniteScroller component rerender -> renders the correct list of learning activities
     * - setting it to empty array doesn't trigger an additional call to /search endpoint (setting it to null would)
     */
    case CLEAR_BROWSE_LEARNING_ACTIVITIES: {
      return {
        ...state,
        browseLearningActivities: [],
        error: null
      };
    }
    case LEARNING_ACTIVITIES_WITH_FILTERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case LEARNING_ACTIVITIES_LOADING:
      return { ...state, loading: true };
    case LEARNING_ACTIVITIES_WITH_FILTER_GET: {
      return {
        ...state,
        loading: false,
        error: null,
        browseLearningActivities: {
          [action.payload.language]: updateBrowseLearningActivitiesByLanguage(
            state.browseLearningActivities,
            action.payload.language,
            action.payload.data.items.learningActivities
          )
        },
        browseTraceId: action.payload.traceId,
        browseTotalCountForQuery: action.payload.data.totalCountForQuery
      };
    }
    case GROUPED_LEARNING_ACTIVITIES_LOADING: {
      return {
        ...state,
        groupedLoading: true
      };
    }
    case GROUPED_LEARNING_ACTIVITIES_GET_WITH_QUERY: {
      return {
        ...state,
        groupedLoading: false,
        groupedLearningActivities: {
          bookmarked: action.payload.data.bookmarked.map(
            (learningActivity: LearningActivityLearningNuggetDto | LearningActivityCollectionDto) =>
              mapLearningActivity(learningActivity)
          ),
          completed: action.payload.data.completed.map(
            (learningActivity: LearningActivityLearningNuggetDto | LearningActivityCollectionDto) =>
              mapLearningActivity(learningActivity)
          ),
          remaining: action.payload.data.remaining.map(
            (learningActivity: LearningActivityLearningNuggetDto | LearningActivityCollectionDto) =>
              mapLearningActivity(learningActivity)
          )
        },
        error: null,
        traceId: action.payload.traceId || null
      };
    }
    case RECENTLY_VIEWED_CLEAR: {
      return {
        ...state,
        recentlyViewedLearningActivities: null,
        recentlyViewedTraceId: null,
        error: null
      };
    }
    case RECENTLY_VIEWED_ERROR: {
      return {
        ...state,
        recenltyViewedLoading: false,
        recenltyViewedError: action.payload
      };
    }
    case RECENTLY_VIEWED_LOADING:
      return { ...state, recenltyViewedLoading: true };
    case RECENTLY_VIEWED_GET:
      return {
        ...state,
        recenltyViewedLoading: false,
        recenltyViewedError: null,
        recentlyViewedLearningActivities: {
          [action.payload.language]: action.payload.data.learningActivities.map(
            (learningActivity: LearningActivityLearningNuggetDto | LearningActivityCollectionDto) =>
              mapLearningActivity(learningActivity)
          )
        },
        recentlyViewedTraceId: action.payload.traceId
      };
    default:
      return state;
  }
};
