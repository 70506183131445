//  @ts-check
/** @type {'long'} */
const long = 'long';
/** @type {'2-digit'} */
const twoDigit = '2-digit';
/** @type {'short'} */
const short = 'short';
/** @type {'numeric'} */
const numeric = 'numeric'; // single digit if applicable (e.g. the day in April 4)

const singleDigitDayAbbrWeekdayFullMonth = { weekday: short, month: long, day: numeric };
const abbrMonthYear = {
  month: short,
  year: numeric
};
const singleDigitDayAbbrWeekdayAbbrMonthYear = {
  weekday: short,
  month: short,
  day: numeric,
  year: numeric
};
const dayAbbrWeekdayFullMonth = {
  weekday: short,
  month: long,
  day: numeric
};
const singleDigitDayAbbrWeekdayAbbrMonth = {
  weekday: short,
  month: short,
  day: numeric
};
const singleDigitDayFullWeekdayFullMonth = { weekday: long, month: long, day: numeric };
const abbrWeekdayNumericDayFullMonthHourMinute = {
  weekday: short,
  day: numeric,
  month: long,
  hour: twoDigit,
  minute: twoDigit
};
const abbrWeekdayNumericDayFullMonthHourMinuteTimeZone = {
  weekday: short,
  day: numeric,
  month: long,
  hour: twoDigit,
  minute: twoDigit,
  timeZone: long
};
const fullMonthYear = { month: long, year: numeric };
/** @param {string} langCode */
const toHyphen = langCode => (langCode?.includes('_') ? langCode.replace('_', '-') : langCode);

/** @param {Intl.DateTimeFormatOptions} formatOptions */
const formatter =
  formatOptions =>
  /**
   * @param {string} lang
   * @param {Date | string | number} date
   * @param {string | null} timeZone
   */
  (lang, date, timeZone = null) => {
    const eventDate = date instanceof Date ? date : new Date(date);
    const langWithHyphen = toHyphen(lang) || 'en-GB';
    const options = timeZone ? { ...formatOptions, timeZone } : formatOptions;
    const formatter_ = new Intl.DateTimeFormat(langWithHyphen, options);
    return formatter_.format(eventDate);
  };

/**
 * TODO FE-498: split or mark all non-locale-aware date formats and communicate use-cases (user-facing vs non-user-facing)
 * @see dateFormats.spec.js for examples to each format. Note that the output changes according to the language settings.
 */
const dateFormats = {
  /** @deprecated For user-facing dates, use `localeHourMinuteSecond` instead for automatic localization. */
  HHmmss: 'HH:mm:ss', // used internally (i.e. not displayed to user)
  /**  @deprecated For user-facing dates, use another format for automatic localization. */
  MMM_YYYY: 'MMM YYYY', // TODO FE-498 same as { month: long, year: numeric } or { month: short, year: numeric } ?
  /**  @deprecated For user-facing dates, use another format for automatic localization. */
  YYYYMMDD: 'YYYYMMDD', // used for grouping in Threads, date comparison in helper.js
  /**  @deprecated For user-facing dates, use another format for automatic localization. */
  YYYYhMMhDD: 'YYYY-MM-DD', // used to format dates in calendar view
  /**  @deprecated For user-facing dates, use another format for automatic localization. */
  YYYYMMDDTHHmm00: 'YYYYMMDD[T]HHmm[00]', // doesnt have to be localised, used for URL to add to calendar
  /**  @deprecated For user-facing dates, use another format for automatic localization. */
  YYYYMMDD_HHmm_Z: 'YYYY-MM-DD HH:mm Z', // used for date parsing with timeZone, the display is handled with localised format
  /**  @deprecated For user-facing dates, use another format for automatic localization. */
  YYYYhMMhDDTHHmm00: 'YYYY-MM-DD[T]HH[:]mm[:00]', // used for link generation, it's not user facing
  dddd: 'dddd', // this returns only the weekday, it gets translated via moment too,
  YYYY: 'YYYY', // for datepicker header, diplays year only,
  DD_MMM_YYYY: 'DD MMM YYYY', // for coachees table
  localeNumericDayAbbrWeekdayFullMonth: formatter(singleDigitDayAbbrWeekdayFullMonth),
  localeNumericDayAbbrWeekdayAbbrMonthYear: formatter(singleDigitDayAbbrWeekdayAbbrMonthYear),
  localeNumericDayAbbrWeekdayAbbrMonth: formatter(singleDigitDayAbbrWeekdayAbbrMonth),
  dayAbbrWeekdayFullMonth: formatter(dayAbbrWeekdayFullMonth),
  localeAbbrMonthYear: formatter(abbrMonthYear),
  localeNumericDayFullWeekdayFullMonth: formatter(singleDigitDayFullWeekdayFullMonth),
  fullMonthYear: formatter(fullMonthYear),
  abbrWeekdayNumericDayFullMonthHourMinute: formatter(abbrWeekdayNumericDayFullMonthHourMinute),
  abbrWeekdayNumericDayFullMonthHourMinuteTimeZone: formatter(
    abbrWeekdayNumericDayFullMonthHourMinuteTimeZone
  ),
  localeDayMonthYear: 'L',
  localeDayFullMonthYear: 'LL',
  localeDayFullMonthYearHourMinutes: 'LLL',
  localeDayNameDayFullMonthYearHourMinutes: 'LLLL',
  localeSingleDigitDayMonthYear: 'l',
  localeDayMonthAbbrYear: 'll',
  localeDayMonthAbbrYearHourMinutes: 'lll',
  localeDayNameAbbrDayMonthAbbrYearHourMinutes: 'llll',
  localeHourMinute: 'LT',
  localeHourMinuteSecond: 'LTS',
  localeHourMinuteDayAbbrMonthYear: 'll LT',
  z: 'z',
  ZZ: 'ZZ',
  HHmmz: 'LT z',
  /** @param {string=} timeZone */
  HHmmZOrGmtFallback: timeZone => `LT ${dateFormats.abbreviatedTimeZone(timeZone)}`,
  /** @param {string=} timeZone */
  abbreviatedTimeZone: timeZone => `[${timeZone || '[(GMT]Z[)]'}]` // use default gmt in timeZone
};

export default dateFormats;
