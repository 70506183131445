import { Color, Theme } from '@material-ui/core';
import { Moment } from 'moment';
import { AddingDocument, ID } from '@coachhubio/document-upload';
import { CoachingStatusValue } from '../components/coach/coachees/coachees-list/model';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import {
  AssignedLearningNuggetsDto,
  BookmarkDto,
  LearningNuggetProgressDto
} from '../apis/learningEngagement.api';

export enum AvatarColor {
  AVATAR_COLOR_COACH = 'AVATAR_COLOR_COACH',
  AVATAR_COLOR_COACHEE = 'AVATAR_COLOR_COACHEE'
}

export enum MilestoneStatus {
  Done = 'DONE',
  Todo = 'TODO'
}

export interface KeyValuePair {
  [key: string]: any;
}

export interface PasswordRules {
  longerRule: boolean;
  lowercaseRule: boolean;
  uppercaseRule: boolean;
  numberRule: boolean;
}

export interface PasswordField {
  error: boolean;
  msg: string;
  isVisible: boolean;
}

export interface BaseMilestone {
  title: string;
  status: MilestoneStatus;
}

export interface Milestone extends BaseMilestone {
  id: string;
  title: string;
  status: MilestoneStatus;
  description?: string;
  createdBy?: string;
  milestoneTitle?: string;
  userId?: string;
  createdAt?: Date;
  originCreatedAt?: Date;
}

export interface BaseGoal {
  title: string;
  description: string;
  id: string;
}

export interface Goal extends BaseGoal {
  userId: string;
  createdBy: string;
  archivedAt?: Date;
  originCreatedAt?: Date;
  originUpdatedAt?: Date;
  createdAt?: Date;
  updatedAt: string;
  milestones: Milestone[];
}

export interface ColorPalette extends PaletteOptions {
  underReview: string;
  blue: string;
  guyabano: string;
  lightBlueCyan: string;
  errorColor: string;
  lightGrey: string;
  neutralGrey: string;
  secondaryPaleBlue: string;
  titleColor: string;
  darkBlueColor: string;
  coral: string;
  subTitleColor: string;
  tabColor: string;
  linkColor: string;
  iconColor: string;
  infoColor: string;
  inputColor: string;
  headerCardColor: string;
  white: string;
  yellow: string;
  deepBlue: string;
  deepKoamaru: string;
  dodgerBlue: string;
  darkGrey: string;
  clearGrey: string;
  neutral95: string;
  darkerGrey: string;
  suvaGrey: string;
  chineseSilver: string;
  veryLightBlue: string;
  orange: string;
  black: string;
  greyContainer: string;
  greyText: string;
  textPrimaryDefault: string;
  greyInfo: string;
  midGrey: string;
  greySeparator: string;
  alertHintBg: string;
  primary: {
    main: string;
  };
  secondary: {
    main: string;
  };
  alertRed: string;
  lightAlertRed: string;
  mediumAlertRed: string;
  lightSteelBlue: string;
  alertSecondaryText: string;
  alertHintText: string;
  sectionSeparatorColor: string;
  greyFilters: string;
  greyFiltersV2: string;
  grey5: string;
  grey: Partial<Color> | undefined;
  filtersDropdownShadow: string;
  alertInformationBg: string;
  lightBlue: string;
  darkOrange: string;
  darkRed: string;
  darkBlue: string;
  darkBlueV2: string;
  boxShadowActiveInput: string;
  checkboxBlue: string;
  modalBackground: string;
  alertWarningBg: string;
  alertSuccessBg: string;
  alertSuccessText: string;
  alertWarningText: string;
  green: string;
  grey3: string;
  alertErrorBg: string;
  lightCyan: string;
  errorMain: string;
}

export type MenuStyles = {
  menuIcon: any;
  mobileMenuContent: any;
  mobileMenuLink: any;
  logo: any;
  smallLogo: any;
  logoLink: any;
  smallLogoLink: any;
  bigAvatar: any;
  mobileAvatar: any;
  nav: any;
};

export interface CoachHubTheme extends Omit<Theme, 'palette'> {
  palette: ColorPalette;
  horizontalMenuItem: any;
  horizontalMenuSelected: any;
  boxStyle: { boxShadow: string };
  pageTopSpacing: { marginTop: string };
  menuSelected: any;
  menuItem: any;
  primaryButton: any;
  textFieldStyles: any;
  menuIcon: any;
  logo: any;
  logoLink: any;
  menuStyles: MenuStyles;
  tabStyles: any;
  fontFamily: any;
  border: string;
}
export interface FullName {
  firstName: string;
  lastName: string;
}

export type User = {
  firstName: string;
  lastName: string;
  jobTitle?: string;
  email: string;
  picture: string;
  lang: string;
  timezone: string;
  roles: UserRole;
  regionId: string;
};

export type CoacheeUser = {
  firstName: string;
  lastName: string;
  jobTitle?: string;
  email: string;
  picture: string;
  lang: string;
  timezone: string;
  roles: UserRole;
  coach: {
    _id: string;
    username: string;
    prefix: string;
    name: {
      firstName: string;
      lastName: string;
    };
    roles: UserRole;
    email: string;
    picture: string;
    timezone: string;
    lang: string;
    coachees: string[];
    schedule: {
      timekitResourceId: string;
    };
    title: string;
    background: string;
    coachingExperience: string;
    workingExperience: string;
    philosophy: string;
    testimonials: string;
    company: '';
    userId: string;
    introVideoDeeplink: string;
    videoLanguage: string;
    nativeLanguages: string[];
    otherLanguages: string[];
    firstName: string;
    lastName: string;
  };
  coachProfile: Record<string, unknown>;
  error: any;
  coacheeList: [];
  loading: boolean;
  coachee: Record<string, unknown>;
  _id: string;
  username: string;
  createdAt: string;
  lastModified: string;
  prefix: string;
  name: {
    firstName: string;
    lastName: string;
  };
  regionId: Tenant;
  title: boolean;
  coachees: boolean;
  background: boolean;
  coachingExperience: boolean;
  philosophy: boolean;
  testimonials: boolean;
  workingExperience: boolean;
  organizationId: string;
  company: string;
  coachingSessionsCount: string;
};

export enum UserType {
  Coach = 'coach',
  Coachee = 'coachee'
}

export enum Tenant {
  eu = 'eu-1',
  us = 'us-1'
}

export type Field = {
  error: boolean;
  required: boolean;
  msg: string;
};

export interface UserRole {
  type?: UserType;
}
export interface Coachee {
  coachingSessionsCount: number;
  company: string;
  completedSessions: number;
  createdAt: Date;
  email: string;
  jobTitle: string;
  lang: string;
  name: FullName;
  organizationId: string;
  picture: string;
  prefix: string;
  roles: UserRole;
  timezone: string;
  upcomingSessionDateTime?: Date;
  _id: string;
  regionId?: Tenant;
}

export enum AssignedLearningNuggetStatus {
  Open = 'OPEN',
  Completed = 'DONE'
}

export enum LearningNuggetStatus {
  Open = 'OPEN',
  Completed = 'COMPLETED'
}

export type EmptyFocusAreaState = {
  icon: string;
  status: string;
  title: (n: number) => string;
};
// TODO Change this with dynamic values from lokalise
export enum NuggetType {
  Video = 'Video',
  Audio = 'Audio',
  Exercise = 'Exercise',
  Reading = 'Reading'
}

export type FocusArea = {
  categoryId: string;
  categoryText: string;
  currentRatingValue: number;
  defaultTitle: string;
  description: string;
  goalRatingValue: number;
  headerImage: string;
  icon: string;
  id: string;
  isMultiRaterEligible: boolean;
  keywords?: string;
  labels: string[];
  labelText: string;
  order: string;
  title: string;
};

export type FocusAreaItem = {
  item: FocusArea;
};

export enum FocusAreaRatingType {
  Current = 'CURRENT',
  Goal = 'GOAL'
}

export interface FocusAreaSingleRating {
  coacheeId: string;
  focusAreaId: string;
  rating: number;
  type: FocusAreaRatingType;
}

export interface FocusAreaRating {
  coacheeId: string;
  focusAreaId: string;
  current: number;
  goal: number;
}

export type SelectedFocusArea = {
  coacheeId: string;
  createdAt: string;
  focusAreaId: string;
  id: string;
  programAttendanceId: string | null;
  selectedAt: string;
  updatedAt: string;
};

export type RatedFocusArea = {
  coacheeId: string;
  createdAt: string;
  focusAreaId: string;
  id: string;
  programAttendanceId: string | null;
  ratedAt: string;
  rating: number;
  type: FocusAreaRatingType;
  updatedAt: string;
};

export type FocusAreaRatingValues = {
  currentRatingValue: number;
  goalRatingValue: number;
};

export interface FocusAreaSelectionPostData {
  userID: string;
  newSelectedFocusAreaId: string;
}

export interface FocusAreaSelectionPostInputPayload {
  coacheeId: string;
  focusAreaId: string;
  programId?: string;
}

export type FocusAreasGroupedByLabel = {
  label: string;
  focusAreas: FocusArea[];
};

export enum LearningActivityFocusAreas {
  Appreciation = 'Appreciation',
  Authenticity = 'Authenticity',
  Centeredness = 'Centeredness',
  Collaboration = 'Collaboration',
  Communication = 'Communication',
  ConflictManagement = 'Conflict Management',
  Delegation = 'Delegation',
  EmotionalRegulation = 'Emotional Regulation',
  Feedback = 'Feedback',
  GoalSetting = 'Goal Setting',
  GrowthMindset = 'Growth Mindset',
  Mindfulness = 'Mindfulness',
  Motivation = 'Motivation',
  ProblemSolving = 'Problem Solving',
  Resilience = 'Resilience',
  TimeManagement = 'Time Management',
  Values = 'Values',
  Vision = 'Vision'
}

export enum WebflowType {
  Exercise = 'EXERCISE',
  Video = 'VIDEO',
  Reading = 'READING'
}

export interface VideoContent {
  description: string;
  url: string;
  subtitle: string;
  title: string;
}

export interface Content {
  title: string;
  body: string;
}

export enum LearningLibraryLanguages {
  'de_DE' = 'de_DE',
  'en_GB' = 'en_GB',
  'fr_FR' = 'fr_FR',
  'es_ES' = 'es_ES',
  'it_IT' = 'it_IT',
  'nl_NL' = 'nl_NL',
  'pt_PT' = 'pt_PT',
  'pt_BR' = 'pt_BR',
  'ja_JP' = 'ja_JP',
  'zh_CN' = 'zh_CN',
  'zh_tw' = 'zh_tw',
  'he_HE' = 'he_HE',
  'ko_KR' = 'ko_KR',
  'hu_HU' = 'hu_HU',
  'pl_PL' = 'pl_PL',
  'ru_RU' = 'ru_RU',
  'ro_RO' = 'ro_RO',
  'tr_TR' = 'tr_TR'
}

export interface AssignedLearningNugget {
  assignedBy: string;
  assignedTo: string;
  createdAt: Date;
  id: string;
  learningNuggetContent: AssignedLearningNuggetContent;
  status: AssignedLearningNuggetStatus;
}

export enum CollectionProgressStatus {
  NOT_STARTED = 'NOT_STARTED',
  VIEWED = 'VIEWED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_COMPLETION = 'WAITING_FOR_COMPLETION',
  COMPLETED = 'COMPLETED'
}

export enum LearningNuggetProgressStatus {
  NOT_STARTED = 'NOT_STARTED',
  OPEN = 'OPEN',
  DONE = 'DONE'
}

export interface LearningNuggetProgress {
  status: LearningNuggetProgressStatus;
}
export type LearningActivityLanguages =
  | 'de_DE'
  | 'en_GB'
  | 'fr_FR'
  | 'es_ES'
  | 'it_IT'
  | 'nl_NL'
  | 'pt_PT'
  | 'pt_BR'
  | 'ja_JP'
  | 'zh_CN'
  | 'zh_tw'
  | 'he_HE'
  | 'ko_KR'
  | 'hu_HU'
  | 'pl_PL'
  | 'ru_RU'
  | 'ro_RO'
  | 'tr_TR';

export enum LearningActivityCardLanguages {
  'de_DE' = 'DE',
  'en_GB' = 'EN',
  'fr_FR' = 'FR',
  'es_ES' = 'ES',
  'it_IT' = 'IT',
  'nl_NL' = 'NL',
  'pt_PT' = 'PT',
  'pt_BR' = 'PT',
  'ja_JP' = 'JP',
  'zh_CN' = 'CN',
  'zh_tw' = 'CN',
  'he_HE' = 'HE',
  'ko_KR' = 'KO',
  'hu_HU' = 'HU',
  'pl_PL' = 'PL',
  'ru_RU' = 'RU',
  'ro_RO' = 'RO',
  'tr_TR' = 'TR'
}

export interface LocalisedLearningNugget {
  id: string;
  localisedId: string;
  learningNuggetId: string;
  legacyId?: string;
  locale: LearningActivityLanguages;
  content: any;
  createdAt: Date;
  bookmarks: BookmarkDto[];
  assignedLearningNuggets: AssignedLearningNuggetsDto[];
  ratingLearningNuggetEvent?: LearningNuggetEventRating;
  storyblokId: string;
  updatedAt: Date;
  nonNegativeRatingCount?: number;
  progress?: LearningNuggetProgressDto;
  next?: any;
}

export interface AssignedLearningNuggetContent {
  id: string;
  name: string;
  assignedBy: string;
  focusArea: LearningActivityFocusAreas;
  type: NuggetType | WebflowType;
  nuggetTypeTitle: string;
  imageHeader: string;
  content: any[];
  video: any;
  introduction: string;
  infoText: string;
  language: string;
  creationDate: Date;
  assignedLearningNuggetId: string;
  status: LearningNuggetStatus;
}

export enum LearningActivityType {
  Nugget = 'Nugget',
  Collection = 'Collection'
}

export enum LearningActivityCategory {
  Balance = 'Balance',
  Communication = 'Communication',
  Empowerment = 'Empowerment',
  Performance = 'Performance',
  Presence = 'Presence',
  Purpose = 'Purpose'
}

export interface LearningNugget {
  id: string;
  learningNuggetId: string;
  localisedLearningNuggetId: string;
  name: string;
  introduction: string;
  infoText: string;
  mainCategory: LearningActivityCategory;
  focusAreas: LearningActivityFocusAreas[];
  type: NuggetType;
  provider: string;
  video?: any;
  nuggetTypeTitle: string;
  imageHeader: string;
  content: any[];
  locale: LearningActivityLanguages;
  rating?: string;
  feedbackMessage?: string;
  assignedLearningNuggets: any[];
  likes: number;
  next?: any;
  collections?: LearningActivityCollection[];
  progress?: LearningNuggetProgress;
}

export interface LearningActivityNugget extends LearningNugget {
  _type: LearningActivityType.Nugget;
}

export interface LearningActivityCollection extends Collection {
  _type: LearningActivityType.Collection;
}

export type LearningActivity = LearningActivityNugget | LearningActivityCollection;

export interface LearningNuggetEventRating {
  rating: LearningNuggetRating;
  feedbackMessage?: string;
}

export enum LearningNuggetRating {
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE'
}

export interface LearningActivityPreviewImage {
  alt: string;
  title: string;
  filename: string;
}

export interface CollectionProgress {
  status: CollectionProgressStatus;
  countCompletedNuggets: number;
}

export interface Collection {
  id: string;
  localisedId: string;
  bookmarks: any[];
  locale: LearningActivityLanguages;
  title: string;
  subtitle: string;
  introText: string;
  sequenceType: CollectionSequenceType;
  externalMediaId: string;
  category: LearningActivityCategory;
  focusAreas: LearningActivityFocusAreas[];
  estimatedDurationInMinutes: number | null;
  seriesId: string | null;
  seasonNumber: number | null;
  learningNuggetIds: string[];
  previewImage?: LearningActivityPreviewImage;
  provider: string;
  createdAt: Date;
  updatedAt: Date;
  progress?: CollectionProgress;
  localisedLearningNuggets?: LocalisedLearningNugget[];
  next?: Collection | null;
}

export enum CollectionSequenceType {
  NO_ORDER_INDICATED = 'NO_ORDER_INDICATED',
  ORDER_INDICATED = 'ORDER_INDICATED',
  CONDITIONAL_ORDER_INDICATED = 'CONDITIONAL_ORDER_INDICATED'
}

// TODO: LearningNuggetStatus and ActivityStatus should be aligned
export enum ActivityStatus {
  Todo = 'TODO',
  Completed = 'COMPLETED'
}

export type Activity = {
  title: string;
  description: string;
  createdBy: string;
  userId: string;
  assignedBy?: string;
  coacheeId: string;
  icon?: string;
  actionDate?: string;
  documents?: AddingDocument[];
  documentIds: string[];
  status: ActivityStatus;
  startsAt: string;
  archivedAt: string | null;
  originCreatedAt?: Date | null;
  originUpdatedAt?: Date | null;
  videos: string[];
  editActivity?: (activityId: string, coacheeId: string) => void;
  editActivityLabel?: string;
  _id: string;
  id: string;
};

export type ActivityItem = {
  coacheeId?: string;
  startsAt: Date | Moment | null;
  title: string;
  description: string;
  draftIds?: Array<string> | null;
  assignedBy?: string;
  status?: ActivityStatus | null;
  removedDocumentsIds?: ID[];
  programId?: string;
};

export interface Avatar {
  avatar: string;
  color: string;
  firstName: string;
  lastName: string;
  title: string;
}

export interface ActivityWithAvatar extends Activity {
  avatar: Avatar;
}

export enum SessionStatus {
  Proposed = 'PROPOSED',
  Scheduled = 'SCHEDULED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Unattended = 'UNATTENDED'
}

export enum SessionSubStatus {
  By_Coach = 'BY_COACH',
  By_Coachee = 'BY_COACHEE',
  By_Coach_Proposal = 'BY_COACH_PROPOSAL',
  By_Coachee_Proposal = 'BY_COACHEE_PROPOSAL',
  By_Coachee_Late_Cancel = 'BY_COACHEE_LATE_CANCEL',
  By_Coachee_Reschedule = 'BY_COACHEE_RESCHEDULE',
  By_Both = 'BY_BOTH',
  Unconfirmed = 'UNCONFIRMED',
  Via_Coachhub = 'VIA_COACHHUB',
  Via_External = 'VIA_EXTERNAL',
  To_Investigate = 'TO_INVESTIGATE',
  Annulled = 'ANNULLED'
}

export enum SessionType {
  Individual = 'INDIVIDUAL',
  Collective = 'COLLECTIVE'
}

// partial interface
export interface Session {
  id: string;
  startsAt: string;
  type: SessionType;
  status: SessionStatus;
  subStatus: SessionSubStatus;
}

export interface BookableTimeframe {
  endAt: string;
  remainingSessions: number;
  startAt: string;
  totalSessions: number | null; // "null" means unlimited coaching
}

export type Quota = {
  coacheeId: string;
  coachingAgreementUpdatedAt: string;
  conducted: number;
  createdAt: string;
  endsAt: string;
  id: string;
  limit: number | null;
  programId: string;
  scheduled: number;
  startsAt: string;
  updatedAt: string;
};

export enum QuestionType {
  Single = 'SINGLE_CHOICE',
  Multi = 'MULTI_CHOICE'
}

export type Answer = {
  id: string;
  label: string;
  status: CoachingStatusValue;
  value: string;
};

export type MatchingAnswer = {
  answerOptionIds: Answer[];
  questionId: string;
};

export type MatchingQuestionnaireAnswer = { answerOptionIds: string[]; questionId: string };

export type Question = {
  id: string;
  category: string;
  name: string;
  status: string;
  type: QuestionType;
  answerOptions: Answer[];
  maxAnswers?: number;
  subtitleLong: string;
  subtitleShort: string;
};

export type Category = {
  id: string;
  image: string;
  label: string;
  name: string;
  questions: Question[];
};

export type Coach = {
  userId: string;
  title: string;
  philosophy: string;
  background: string;
  coachingExperience: string;
  workingExperience: string;
  testimonials: string;
  introVideoDeeplink: string;
  firstName: string;
  lastName: string;
  picture: string;
  nativeLanguages?: string[];
  otherLanguages?: string[];
  videoLanguage: string;
  proposalId: string;
};

export interface CoachProposal extends Coach {
  shortProposal: boolean;
  status: string;
  createdAt: string;
  updatedAt: string;
  proposalCreatedAt?: string;
  proposalUpdatedAt?: string;
}

export type MappedAnswer = { answerOptionIds: string[]; questionId: string };

export type TimeZoneObject = {
  tzCode: string;
  label: string;
  offset: string;
};

export type Result = {
  description: string;
  focusAreaId: string;
  scale: string;
  value: string;
};

export type Program = {
  id: string;
  name: string;
  description: string;
  createdAt: number;
};

export type CoacheeProgramList = { id: string; name: string; description: string }[];

export type SingleChat = {
  receiverID: string;
  messages: Message[];
  id: string;
};

export type Message = {
  id: string;
  payload: {
    content: string;
  };
  type: string;
  sender: string;
  sentAt: string;
  receivedAt: string;
  receipts: Receipt[];
  messageFrom: string;
  message: string;
};

export type Receipt = {
  id: string;
  type: string;
  sender: string;
  sentAt: string;
  receivedAt: string;
};

export enum LegacyCoacheeOnboardingStep {
  FIRST_LOGIN = 'FIRST_LOGIN',
  PROFILE_COMPLETED = 'PROFILE_COMPLETED',
  MATCHING_COMPLETED = 'MATCHING_COMPLETED',
  MATCHING_SUPPORT = 'MATCHING_SUPPORT',
  MATCHING_TIMEOUT = 'MATCHING_TIMEOUT',
  MATCHING_PROGRESS = 'MATCHING_PROGRESS',
  SESSION_SCHEDULED = 'SESSION_SCHEDULED',
  ASSESSMENT_COMPLETED = 'ASSESSMENT_COMPLETED',
  COACH_INTRODUCED = 'COACH_INTRODUCED',
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED'
}

export enum CoacheeOnboardingDisplayScreen {
  FIRST_LOGIN = 'FIRST_LOGIN',
  PROFILE_TO_COMPLETE = 'PROFILE_TO_COMPLETE',
  PROFILE_COMPLETED = 'PROFILE_COMPLETED',
  MATCHING_COMPLETED = 'MATCHING_COMPLETED',
  MATCHING_QUESTIONS = 'MATCHING_QUESTIONS',
  MATCHING_CATEGORY_FINISHED = 'MATCHING_CATEGORY_FINISHED',
  MATCHING_TIMEOUT = 'MATCHING_TIMEOUT',
  COACH_PROPOSALS = 'COACH_PROPOSALS',
  ASSESSMENT_COMPLETED = 'ASSESSMENT_COMPLETED',
  ASSESSMENT_QUESTIONS = 'ASSESSMENT_QUESTIONS',
  SESSION_SCHEDULED = 'SESSION_SCHEDULED',
  SESSION_CALENDAR = 'SESSION_CALENDAR',
  SESSION_CONFIRMATION = 'SESSION_CONFIRMATION',
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED',
  COACH_NOT_FOUND = 'COACH_NOT_FOUND',
  APP_NO_ACCESS = 'APP_NO_ACCESS',
  COACH_INTRODUCED = 'COACH_INTRODUCED'
}

export enum CoachOnboardingStep {
  FIRST_LOGIN = 'FIRST_LOGIN',
  PROFILE_COMPLETED = 'PROFILE_COMPLETED',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED'
}

export type CoachSessionAvailability = {
  start: string | Date;
  end: string | Date;
};

export const CONFIRMED_PARAM = 'confirmed';

// Languages for which we have translations in Lokalise
export enum SupportedLocale {
  EnglishFromTheUK = 'en_GB',
  ArabicFromSaudiArabia = 'ar_SA',
  ChineseSimplifiedFromChina = 'zh_CN',
  ChineseTraditionalFromTaiwan = 'zh_TW',
  DanishFromDenmark = 'da_DK',
  DutchFromTheNetherlands = 'nl_NL',
  FrenchFromFrance = 'fr_FR',
  GermanFromGermany = 'de_DE',
  HebrewFromIsrael = 'he_HE',
  HungarianFromHungary = 'hu_HU',
  IndonesianFromIndonesia = 'id_ID',
  ItalianFromItaly = 'it_IT',
  JapaneseFromJapan = 'ja_JP',
  KoreanFromSouthKorea = 'ko_KR',
  PolishFromPoland = 'pl_PL',
  PortugueseFromBrazil = 'pt_BR',
  PortugueseFromPortugal = 'pt_PT',
  RomanianFromRomania = 'ro_RO',
  RussianFromRussia = 'ru_RU',
  SpanishFromSpain = 'es_ES',
  SwedishFromSweden = 'sv_SE',
  TurkishFromTurkey = 'tr_TR',
  VietnameseFromVietnam = 'vi_VN'
}

export const specialLocales = ['pt_BR'];

export const DEFAULT_LANGUAGE = SupportedLocale.EnglishFromTheUK;
