export const GET_BOOKABLE_TIMEFRAMES = 'GET_BOOKABLE_TIMEFRAMES';
export const CREATE_SESSION = 'CREATE_SESSION';
export const CANCEL_SESSION = 'CANCEL_SESSION';
export const GET_AVAILABILITIES = 'GET_AVAILABILITIES';
export const GET_DETAILED_AVAILABILITY_INFO = 'GET_DETAILED_AVAILABILITY_INFO';
export const SESSIONS_ERROR = 'SESSIONS_ERROR';
export const SESSIONS_LOADING = 'SESSIONS_LOADING';
export const AVAILABILITIES_LOADING = 'AVAILABILITIES_LOADING';
export const DETAILED_AVAILABILITY_INFO_LOADING = 'DETAILED_AVAILABILITY_INFO_LOADING';
export const AVAILABILITIES_ERROR = 'AVAILABILITIES_ERROR';
export const DETAILED_AVAILABILITY_INFO_ERROR = 'DETAILED_AVAILABILITY_INFO_ERROR';
export const BLOCK_SLOT_AVAILABILITY = 'BLOCK_SLOT_AVAILABILITY';
export const UNBLOCK_SLOT = 'UNBLOCK_SLOT';
export const BLOCK_SLOT_MOVED = 'BLOCK_SLOT_MOVED';
export const GET_BLOCKINGS = 'GET_BLOCKINGS';
export const RESCHEDULE_SESSION = 'RESCHEDULE_SESSION';
export const PENDING_SESSIONS_LOADING = 'PENDING_SESSIONS_LOADING';
export const SET_PENDING_SESSIONS_COACH = 'SET_PENDING_SESSIONS_COACH';
export const SET_PENDING_SESSIONS_COACHEE = 'SET_PENDING_SESSIONS_COACHEE';
export const GET_SESSIONS_BY_USER = 'GET_SESSIONS_BY_USER';
export const GET_DETAILED_AVAILABILIY_INFO = 'GET_DETAILED_AVAILABILIY_INFO';
