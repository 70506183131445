import { getLocalStorage } from '../../utils/helper';
import * as Types from './types';
import { MatchingState } from '../types';
import { Coach } from '../../model/model';

type QuestionnaireResponse = { coachProposal: Coach };

export type MatchingAction = {
  type: string;
  payload: any;
};

export const initialState = {
  loading: false,
  industries: [],
  functionalExpertises: [],
  matchingSuccessful: null,
  questionnaireId: null,
  categories: [],
  skippedQuestionIndexes: [],
  error: null,
  answers: [],
  proposals: undefined,
  showMoreProposals: getLocalStorage('showMoreProposals') || false,
  hasTimedOut: false,
  isRedoingQuestionnaire: false,
  coachAvailabilities: undefined
};

export const matchingReducer = (state: MatchingState = initialState, action: MatchingAction) => {
  switch (action.type) {
    case Types.MATCHING_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.MATCHING_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case Types.GET_QUESTIONNAIRE: {
      return {
        ...state,
        questionnaireId: action.payload.id,
        categories: action.payload.categories,
        error: null,
        loading: false
      };
    }
    case Types.SUBMIT_QUESTIONNAIRE: {
      return {
        ...state,
        loading: false,
        proposals: action.payload.isStandardizedResponse
          ? action.payload.data
          : action.payload.data?.map(
              (questionnaireResponse: QuestionnaireResponse) => questionnaireResponse.coachProposal
            )
      };
    }
    case Types.MATCHING_SELECTION_SAVE: {
      return {
        ...state,
        answers: [
          ...state.answers.filter(answer => answer.questionId !== action.payload.questionId),
          action.payload
        ]
      };
    }
    case Types.COACH_PROPOSALS_GET: {
      return {
        ...state,
        loading: false,
        error: null,
        proposals: action.payload.proposals?.length ? action.payload.proposals : null
      };
    }
    case Types.COACH_PROPOSALS_SHOW_MORE: {
      return {
        ...state,
        showMoreProposals: true
      };
    }
    case Types.COACH_PROPOSAL_SUBMIT: {
      return {
        ...state,
        loading: false,
        error: null,
        matchingSuccessful: action.payload
      };
    }
    case Types.MATCHING_TIMEOUT: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        hasTimedOut: true
      };
    }
    case Types.RESET_STATE_FOR_RESUBMIT: {
      return {
        ...state,
        proposals: initialState.proposals,
        answers: initialState.answers,
        showMoreProposals: false,
        isRedoingQuestionnaire: true
      };
    }
    case Types.QUESTIONNAIRE_SUBMITTED: {
      return {
        ...state,
        isRedoingQuestionnaire: false
      };
    }
    case Types.COACH_AVAILABILITIES_GET: {
      return {
        ...state,
        loading: false,
        error: null,
        coachAvailabilities: { ...state.coachAvailabilities, ...action.payload }
      };
    }
    case Types.STORE_SKIPPED_QUESTION_INDEXES: {
      return {
        ...state,
        skippedQuestionIndexes: [...state.skippedQuestionIndexes, action.payload]
      };
    }
    default:
      return state;
  }
};
